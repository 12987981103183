import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Fuse from "fuse.js";

export const resolver = (schema) => {
  const yupSchema = Yup.object().shape(schema);
  return yupResolver(yupSchema);
};

export const capitalize = (text) => {
  //split the above string into an array of strings
  //whenever a blank space is encountered

  const arr = text.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  return arr.join(" ");
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, addedSx) => {
  const splittedText = name.split(" ");
  const children =
    splittedText.length > 1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}`;
  return {
    sx: {
      ...addedSx,
      bgcolor: stringToColor(name),
    },
    children,
  };
};

export const getRasaMessages = (conversation = {}) => {
  let messages = [];
  if (conversation?.events?.length > 0) {
    messages =
      conversation?.events
        ?.filter((evnt) => evnt.event === "user" || evnt.event === "bot")
        ?.map((evnt) => ({
          sender: evnt.event === "user" ? "user" : "ruby",
          message: evnt.text,
        })) || [];
  }

  return messages;
};

export const toCurrency = (value) => {
  const currency = new Intl.NumberFormat(process.env?.locale || "en-US", {
    style: "currency",
    currency: process.env?.locale || "USD",
  });

  return currency.format(value);
};

export const confidenceColorLevels = (val) => {
  if (val <= 0.25) {
    return "red";
  } else if (val <= 0.5) {
    return "yellow";
  } else if (val <= 0.75) {
    return "orange";
  } else {
    return "green";
  }
};

export const serverId = process.env.REACT_APP_SERVER_ID;
export const siteId = process.env.REACT_APP_SITE_ID;
export const storeId = process.env.REACT_APP_STORE_ID;
export const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
export const siteName = process.env.REACT_APP_SITE_NAME;

const normalizeText = (text) => {
  if (typeof text !== "string") return null;

  return text?.toLowerCase().replace(/[^a-zA-Z\s]/g, "");
};

export const suggestProduct = (userReply, productList) => {
  const normalizedReply = normalizeText(userReply);
  if (productList.length === 0) {
    return "No items is available.";
  }

  const matchingProducts = productList.filter((item) => {
    const productWords = normalizeText(item.ItemName)?.split(" ");
    return productWords.some((word) =>
      normalizedReply.split(" ").includes(word)
    );
  });

  if (matchingProducts.length === 0) {
    return "Sorry, I couldn't find a match for your preference. Would you like to see our menu?";
  } else if (matchingProducts.length === 1) {
    const item = matchingProducts[0];
    return `Base on your preference we suggest to try our ${item.ItemName}? It's from our ${item.MenuName}.`;
  } else {
    const suggestions = matchingProducts
      .map((item) => item.ItemName)
      .join(" or ");
    return `We have a few options with that and base on your preference: ${suggestions}. Which one would you like?`;
  }
};

export const findPhraseAfterOrder = (sentence) => {
  // Hint example "order", "product name"
  const hint = "order";
  const regex = new RegExp(`${hint}\\s+([^\\.]+)`, "i");
  const match = sentence.match(regex);
  return match ? match[1] : null;
};
const hints = [
  "give me",
  "I want",
  "order",
  "please get",
  "get me",
  "fetch",
  "bring",
  "purchase",
];

export const findItemNameAfterHints = (sentence, items) => {
  const sentenceLower = sentence.toLowerCase();
  for (const hint of hints) {
    const hintLower = hint.toLowerCase();
    if (sentenceLower.includes(hintLower)) {
      for (const item of items) {
        const itemLower = item.toLowerCase();
        if (sentenceLower.includes(itemLower)) {
          return `${hint} ${item}`;
        }
      }
    }
  }

  return null;
};

export const findClosestItem = (
  sentence,
  items,
  threshold = 5,
  availableProducts
) => {
  const startTime = performance.now();
  const sentenceLower = normalizeText(sentence);

  let highScore = 0;
  let closestItem = "";
  for (const item of items) {
    const productWords = normalizeText(item).split(" ");
    let score = 0;

    for (const word of productWords) {
      const wordLower = word.toLowerCase();
      const regexPattern = new RegExp(
        `\\b${wordLower}(s|ed)?\\b|\\b${wordLower.slice(0, -1)}(s|ed)?\\b`,
        "i"
      );
      // Check if each character in the word is within one character of the sentence
      if (regexPattern.test(sentenceLower)) {
        score += 1;
      }
    }

    const calculatedScore =
      sentenceLower.split(" ").length - productWords.length + score;
    if (
      calculatedScore === highScore &&
      productWords.length > closestItem.split(" ").length
    ) {
      highScore = calculatedScore;
      closestItem = item;
    } else if (calculatedScore > highScore) {
      highScore = calculatedScore;
      closestItem = item;
    }
  }
  const endTime = performance.now();
  console.log("Old Search Time: ", endTime - startTime / 1000);
  const selectedItem = availableProducts.find(
    (productItem) =>
      removeSpecialCharacters(productItem.ItemName)?.toUpperCase() ===
      removeSpecialCharacters(closestItem)?.toUpperCase()
  );
  if (highScore < threshold) {
    return {
      foundItem: selectedItem,
      highestScoreItem: null,
    };
  }

  return {
    foundItem: selectedItem,
    highestScoreItem: selectedItem,
  };
};

export const fuseSearch = (sentence, items) => {
  console.log(sentence, items, "TEst");
  const startTime = performance.now();
  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ["ItemName", "Name"],
  };

  const fuse = new Fuse(items, options);

  const result = fuse.search(sentence);
  console.log(result, "Res");
  const endTime = performance.now();
  console.log("Fuse Search Time: ", endTime - startTime / 1000);
  const bestMatch = result.reduce(
    (best, current) => {
      return best.score < current.score ? best : current;
    },
    { score: Infinity }
  );

  return bestMatch?.item ?? null;
};

export const findNumbersInString = (str) => {
  var regex =
    /\b(\d+|(one|two|three|four|five|six|seven|eight|nine|zero|ten))\b/gi;
  return str.match(regex);
};

export const removeSpecialCharacters = (str) => {
  if (typeof str !== "string") return;
  return str.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const detectAffirmationOrDenial = (str) => {
  const affirmations = [
    "yes",
    "yeah",
    "yep",
    "sure",
    "absolutely",
    "indeed",
    "right",
    "correct",
    "true",
  ];
  const denials = ["no", "nah", "nope", "incorrect", "false"];

  let lowerCaseStr = str.toLowerCase();

  for (let affirmation of affirmations) {
    if (lowerCaseStr.includes(affirmation)) {
      return "affirm";
    }
  }

  for (let denial of denials) {
    if (lowerCaseStr.includes(denial)) {
      return "deny";
    }
  }

  return null;
};

export const datectCancel = (str) => {
  const pickupKeywords = ["cancel", "close", "removed"];

  let lowerCaseStr = str.toLowerCase();

  for (let pickupKeyword of pickupKeywords) {
    if (lowerCaseStr.includes(pickupKeyword)) {
      return "cancel";
    }
  }

  return null;
};

export const detectPickup = (str) => {
  const pickupKeywords = ["cancel", "close", "removed"];

  let lowerCaseStr = str.toLowerCase();

  for (let pickupKeyword of pickupKeywords) {
    if (lowerCaseStr.includes(pickupKeyword)) {
      return "cancel";
    }
  }

  return null;
};

export const detectDelivery = (str) => {
  const deliveryKeywords = [
    "delivered",
    "delivery",
    "shipping",
    "shipped",
    "dispatched",
    "courier",
    "parcel",
    "package",
    "tracking",
  ];

  let lowerCaseStr = str.toLowerCase();

  for (let deliveryKeyword of deliveryKeywords) {
    if (lowerCaseStr.includes(deliveryKeyword)) {
      return "delivery";
    }
  }

  return null;
};

export const describeItem = (str, item) => {
  // Base words without tense
  const baseWords = [
    "define",
    "specify",
    "mention",
    "note",
    "highlight",
    "describe",
    "outline",
    "state",
    "express",
    "detail",
  ];
  const lowerCaseStr = str.toLowerCase();

  // Check for base word or its common tense variations
  const hasKeyWord = baseWords.some((baseWord) => {
    const baseForm = new RegExp(`\\b${baseWord}(e|ed|ing|s)?\\b`, "i");
    return baseForm.test(lowerCaseStr);
  });

  if (!hasKeyWord) return null;

  const description = item?.ItemDescription
    ? item?.ItemDescription
    : `Details for ${item?.ItemName} are currently unavailable.`;

  return description;
};

export const detectDone = (str, item) => {
  // Base words without tense
  const baseWords = ["done", "next"];
  const lowerCaseStr = str.toLowerCase();
  // Check for base word or its common tense variations
  const hasKeyWord = baseWords.some((baseWord) => {
    const baseForm = new RegExp(`\\b${baseWord}(e|ed|ing|s)?\\b`, "i");
    return baseForm.test(lowerCaseStr);
  });

  return hasKeyWord ? hasKeyWord : baseWords.includes(lowerCaseStr);
};

export const detectRemove = (str, item) => {
  // Base words without tense
  const baseWords = [
    "remove",
    "cancel",
    "delete",
    "erase",
    "discard",
    "eliminate",
  ];
  const lowerCaseStr = str.toLowerCase();
  // Check for base word or its common tense variations
  const hasKeyWord = baseWords.some((baseWord) => {
    const baseForm = new RegExp(`\\b${baseWord}(e|ed|ing|s)?\\b`, "i");
    return baseForm.test(lowerCaseStr);
  });

  return hasKeyWord ? hasKeyWord : baseWords.includes(lowerCaseStr);
};

export const detectCheckout = (str) => {
  // Base words without tense
  const baseWords = ["check out", "cart", "payment", "check"];
  const lowerCaseStr = str.toLowerCase();

  // Check for base word or its common tense variations
  const hasKeyWord = baseWords.some((baseWord) => {
    const baseForm = new RegExp(`\\b${baseWord}(e|ed|ing|s)?\\b`, "i");
    return baseForm.test(lowerCaseStr);
  });

  return hasKeyWord ? hasKeyWord : baseWords.includes(lowerCaseStr);
};

export const detectOrder = (str) => {
  const orderKeys = ["order", "want", "give me"];

  const regex = new RegExp(orderKeys.join("|"), "i"); // "i" flag for case-insensitive matching

  return regex.test(str);
};

export const detectSuggest = (str) => {
  // Base words without tense
  const baseWords = [
    "suggest",
    "recommend",
    "offer",
    "feature",
    "present",
    "provide",
    "list",
  ];
  const lowerCaseStr = str.toLowerCase();

  // Check for base word or its common tense variations
  const hasKeyWord = baseWords.some((baseWord) => {
    const baseForm = new RegExp(`\\b${baseWord}(e|ed|ing|s)?\\b`, "i");
    return baseForm.test(lowerCaseStr);
  });

  return hasKeyWord ? hasKeyWord : baseWords.includes(lowerCaseStr);
};

export const filterMenuByAllergens = (menuItems, allergenRestrictions) => {
  return menuItems.filter((menuItem) => {
    const allergens = menuItem.allergens || [];

    // Check allergens
    if (
      allergenRestrictions.some((allergen) =>
        allergens.includes(allergen.allergenName)
      )
    ) {
      return false;
    }

    return true;
  });
};

export const filterMenuByDietaryPreference = (
  menuItems,
  dietaryPreferences
) => {
  return menuItems.filter((menuItem) => {
    const allergens = menuItem.allergens || [];
    const dietaryCategories = menuItem.dietaryCategories || [];

    // Check dietary categories
    if (
      !dietaryPreferences.dietaryPreferences.some((diet) =>
        dietaryCategories.includes(diet)
      )
    ) {
      return false;
    }

    // Check allergens
    if (
      dietaryPreferences.allergenRestrictions.some((allergen) =>
        allergens.includes(allergen.allergenName)
      )
    ) {
      return false;
    }

    return true;
  });
};

export const filterMenuByDietary = (menuItem, dietaryPreferences) => {
  const dietaryCategories = menuItem?.dietaryCategories || [];

  // Check dietary categories
  if (!dietaryPreferences.some((diet) => dietaryCategories.includes(diet))) {
    return false;
  }

  return true;
};

export const numberWithCommas = (x = 0, fixed = 2) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(toNumberString(x))
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toNumberString = (val = 0) => {
  // return val.toString().replace(/,/g, '');
  return val.toString().replace(/[^\d.-]*/g, "");
};

export function isStringifiedJSON(str) {
  if (typeof str !== "string") {
    return false; // Ensure input is a string
  }

  try {
    const parsed = JSON.parse(str);
    // Check if the parsed result is an object or array, which confirms it's JSON
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false; // If parsing fails, it's not valid JSON
  }
}

export const generateProductFinderPrompt = (appName, products) => {
  return `
    You are an online waiter at ${appName}.
    menuList: ${products}
    String Rules: 
    1. From the menu list, check all the available items closest to the user input.
    2. When describing an item found in the menu it should have an <item name> and an <item description>.

    3. Your message should be like this:
       If you found an item, you will reply like this: <quantity> <item name> has been added to the guest check. Would you like anything else?
       If no items are found, respond with a message related to the user’s input in normal string format.

    4. If there are items found in the menu list, then your response should be a Stringified JSON with this format:
       {"items": [{ "itemname": "<item name on the menuList>", "itemid": "<item id on the menuList>", "quantity": <item quantity> }], "message": "<your message>" }
       Important: Do not add any extra fields or text outside of this format.

    5. If no items are found in the menu list, respond with a normal string message as usual.
  `;
};
