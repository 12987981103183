import {
  INIT_ORDER,
  SET_CANCEL_ORDER,
  SET_PRE_CANCEL_ORDER,
  SET_END_ORDER_PROCESS,
  RESET_ORDER_AI,
  SELECT_SERVICE_OPTIONS,
  SET_PRODUCT_WITH_MODIFIERS,
} from "./constant";

const initialState = {
  orderStatus: null, //  "order" | "modify" | "quantity" | "suggest" | "anythingelse"
  cancelStatus: null,
  itemDetails: {},
  message: "",
  productsWithModifiers: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case INIT_ORDER:
      state.isOnOrderMode = true;
      state.orderStatus = "order";
      state.itemDetails = payload.productDetails;
      // TODO ADD CONDITION FOR ITEMS WITH MODIFIERS
      state.message = payload.message;
      return state;
    // case ORDER_WITH_MODIFIERS:
    //   state.isOnOrderMode = true;
    //   state.orderStatus = "order-modifier";
    //   state.itemDetails = payload.productDetails;
    //   // TODO ADD CONDITION FOR ITEMS WITH MODIFIERS
    //   state.message = payload.message;
    //   return state;
    // case SET_ORDER_SUGGESTION:
    //   state.isOnOrderMode = true;
    //   state.orderStatus = "suggest";
    //   state.itemDetails = payload;
    //   state.message = `How many ${payload?.ItemName} would you like to order?`;
    //   return state;
    case SELECT_SERVICE_OPTIONS:
      state.isOnOrderMode = true;
      state.orderStatus = "service-options";
      state.itemDetails = { ...state.itemDetails, quantity: payload.quantity };
      state.message = payload?.message;
      return state;
    // case SET_STAFF_TIP:
    //   state.isOnOrderMode = false;
    //   state.orderStatus = "staff-tip";
    //   state.message = payload?.message;
    //   return state;
    // case SET_ON_TIP_PROCESS:
    //   state.isOnOrderMode = false;
    //   state.orderStatus = "tip-process";
    //   state.message = payload?.message;
    //   return state;
    case SET_END_ORDER_PROCESS:
      state.isOnOrderMode = false;
      state.orderStatus = null;
      state.itemDetails = {};
      state.message = payload.message;
      return state;
    case SET_PRE_CANCEL_ORDER:
      return {
        ...state,
        cancelStatus: "pre-cancel",
        message: `I didn't get that, please try again one more time.`,
      };
    case SET_CANCEL_ORDER:
      return {
        orderStatus: null, //  "order" | "modify" | "quantity" | "suggest" | "anythingelse"
        cancelStatus: null,
        itemDetails: {},
        message: payload?.message,
        productsWithModifiers: [],
      };
    case RESET_ORDER_AI:
      return {
        orderStatus: null, //  "order" | "modify" | "quantity" | "suggest" | "anythingelse"
        cancelStatus: null,
        itemDetails: {},
        message: "",
      };

    case SET_PRODUCT_WITH_MODIFIERS:
      return {
        ...state,
        productsWithModifiers: payload,
      };

    default:
      return state;
  }
}
