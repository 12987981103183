import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const welcomeTitle = process.env.REACT_APP_WELCOME_TITLE;
const aboutUsIntro = process.env.REACT_APP_ABOUTUS_INTRO;
const checkOutMenu = process.env.REACT_APP_CHECKOUT_MENU;
const pdfViewer = parseInt(process.env.REACT_APP_PDFVIEWER_NOMENU);

export const ShowCase = () => {
  const history = useHistory();

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  return (
    <Grid
      container
      spacing={4}
      className="margin-top-20"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <Grid
        item
        xs={12}
        className="center aos-init aos-animate"
        data-aos="fade-top"
      >
        <h2 color="inherit" gutterBottom style={{ lineHeight: 1.1 }}>
          Welcome to {restaurantName}. <em>{welcomeTitle}</em>
        </h2>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className="center aos-init aos-animate"
        data-aos="fade-left"
      >
        <CardActionArea
          onClick={() => {
            history.push("/about");
          }}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Card
            elevation={1}
            variant="elevation"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <CardContent style={{ flex: 1 }}>
              <h3 color="inherit" gutterBottom style={{ lineHeight: 1.1 }}>
                Get to know us
              </h3>
              <Typography
                variant="body1"
                paragraph
                className="margin-top-20"
                style={{
                  lineHeight: 1.1,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 3,
                  lineClamp: 3,
                }}
              >
                {aboutUsIntro}
              </Typography>
              <Typography variant="body2" style={{ color: buttonColor }}>
                <i>Continue reading...</i>
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={siteMedSrc + "ShowcaseLeft" + ".webp"}
              title={`${restaurantName} Showcase Image Left`}
              alt={`${restaurantName} Showcase Image Left`}
              loading="lazy"
              style={{ width: "300px", height: "300px", objectFit: "cover" }}
            />
          </Card>
        </CardActionArea>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className="center aos-init aos-animate"
        data-aos="fade-right"
      >
        <CardActionArea
          onClick={() => {
            history.push("/menu");
          }}
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <Card
            elevation={1}
            variant="elevation"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <CardContent style={{ flex: 1 }}>
              <h3 color="inherit" gutterBottom style={{ lineHeight: 1.1 }}>
                Discover Menu
              </h3>
              <Typography
                variant="body1"
                className="margin-top-20"
                paragraph
                style={{
                  lineHeight: 1.1,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 3,
                  lineClamp: 3,
                }}
              >
                {checkOutMenu}
              </Typography>
              <Button
                className="margin-top-10 margin-bottom-10"
                style={{
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
                onClick={() => {
                  history.push("/menu");
                }}
              >
                {pdfViewer === 1 ? "Our Menu" : "Order Now"}
              </Button>
            </CardContent>
            <CardMedia
              component="img"
              image={siteMedSrc + "ShowcaseRight" + ".webp"}
              title={`${restaurantName} Showcase Icon`}
              alt={`${restaurantName} Showcase Icon`}
              loading="lazy"
              style={{ width: "300px", height: "300px", objectFit: "cover" }}
            />
          </Card>
        </CardActionArea>
      </Grid>
    </Grid>
  );
};
