import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AddressMap } from "../components/AddressMap";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import { ContactSlider } from "../components/ContactSlider";
import ContactCard from "../components/ContactCard";
import ImgListCard from "../components/ImgListCard";
import PhoneCard from "../components/PhoneCard";
import DirectionCard from "../components/DirectionCard";
import BusinessCard from "../components/BusinessCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME || "";
const restaurantAddress = process.env.REACT_APP_RESTAURANT_ADDRESS || "";
const restaurantURL = process.env.REACT_APP_SITE;

export const Contact = () => {
  const [contactContent, setContactContent] = useState({
    description: "",
    keywords: "",
    title: "",
  });

  useEffect(() => {
    setContactContent(
      config.contactcontent || { description: "", keywords: "", title: "" }
    );
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 300,
    });
  }, []);

  return (
    <div
      className="Contact"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      // data-aos="fade-top"
    >
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us: {restaurantAddress} </title>
          <link rel="canonical" href={`${restaurantURL}contact`} />
          <meta name="description" content={contactContent.description} />
          <meta name="keywords" content={contactContent.keywords} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${restaurantAddress}`}
          />
          <meta
            property="og:description"
            content={contactContent.description}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${contactContent.title}`}
          />
          <meta
            property="twitter:description"
            content={contactContent.description}
          />
        </Helmet>
      </HelmetProvider>
      <ContactSlider />
      <Container>
        <Row>
          <Col xs={6}>
            <ImgListCard />
          </Col>
          <Col xs={6} className="center">
            <ContactCard />
          </Col>
        </Row>
      </Container>
      <Container fluid="md">
        <Row>
          <Col xs={12} sm={4} className="whitespace left">
            <PhoneCard />
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
            <DirectionCard />
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
            <BusinessCard />
          </Col>
        </Row>
      </Container>
      <AddressMap />
      <Footer />
    </div>
  );
};
