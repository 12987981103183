import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import { AboutSlider } from "../components/AboutSlider";
import { AboutHighlights } from "../components/AboutHighlights";
import { AboutBusiness } from "../components/AboutBusiness";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantURL = process.env.REACT_APP_SITE;

export const About = () => {
  const [aboutContent, setaboutContent] = useState("");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const aboutContentText = config.aboutcontent;

    setaboutContent(aboutContentText);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  return (
    <div
      className="About"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      // data-aos="fade-top"
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${aboutContent.title}`}</title>
          <link rel="canonical" href={`${restaurantURL}about`} />
          <meta name="description" content={`${aboutContent.description}`} />
          <meta name="keywords" content={`${aboutContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${aboutContent.title}`}
          />
          <meta
            property="og:description"
            content={`${aboutContent.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${aboutContent.title}`}
          />
          <meta
            property="twitter:description"
            content={`${aboutContent.description}`}
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <AboutSlider />
      <AboutHighlights />
      <AboutBusiness />
      <Footer />
    </div>
  );
};
