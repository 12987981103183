import React from "react";
import { Typography, Box, ImageList, ImageListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-time-picker/assets/index.css";
import { useRouteMatch } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import PaymentNested from "../components/PaymentNested";
import CustomerSatisfactionQRCode from "features/eComm/components/CustomerSatisfactionQRCode";
import "react-simple-keyboard/build/css/index.css";
import moment from "moment";
import ta from "date-fns/esm/locale/ta/index.js";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const serviceCharge = parseFloat(process.env.REACT_APP_SERVICE_CHARGE);
const tableNumber = sessionStorage.getItem("table");
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const pickupDesc = process.env.REACT_APP_PICKUP_DESC;
const dineInDesc = process.env.REACT_APP_DINE_IN_DESC;
const deliveryDesc = process.env.REACT_APP_DELIVERY_DESC;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const enableQRFooter = process.env.REACT_APP_ENABLE_QR_FOOTER;

const PaymentSelectorSL = ({
  pickupTimebyRevenueCenter,
  selectedTime,
  setOpenUserCards,
  getSubtotalPrice,
  getTotalTaxAmount,
  getTotalDiscount,
  getTotalPrice,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));
  const classes = useStyles();

  //---From world pay component converted for SL meal balance payment---//
  const {
    totalPriceItems,
    tips,
    cartItems,
    promoCode,
    totalDiscount,
    rewardsBalance,
    giftCardBalance,
    orderSession,
  } = useSelector((state) => state.cart);
  const {
    orderProcessingDate,
    selectedAddress,
    orderProcessingType,
    orderTimeSelectValue,
  } = useSelector((state) => state.public);

  const match = useRouteMatch();
  const dispatch = useDispatch();

  // =========== START ==========
  console.log(orderProcessingType, "TYPE PAYMENTSLEECTOR");
  // =========== END ===========
  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);

  const couponAmount = promoCode ? parseFloat(totalDiscount) : 0.0;
  // Need to add deliveryCharge
  // const deliveryCharge = 0;
  const subTotal = getSubtotalPrice();
  const tax = getTotalTaxAmount();
  const discount = getTotalDiscount();
  const total = getTotalPrice();
  //---End From world pay component converted for SL meal balance payment---//

  const setOrder = () => {
    let pickupInstruction;
    if (
      enableRevenueCenter == 1 &&
      pickupTimebyRevenueCenter &&
      pickupTimebyRevenueCenter.length > 0
    ) {
      pickupInstruction = " Pickup Instruction: ";
      pickupTimebyRevenueCenter.map((i) => {
        pickupInstruction =
          pickupInstruction + i.revenueCenter + "=" + i.pickup + " ";
      });
    }
    const url =
      apiBaseURL + "/postpayment/setOrder/" + siteId + "?server_id=" + serverId;
    const d = new Date(orderProcessingDate);
    const formattedDateTime = moment(
      moment(orderProcessingDate).format("MMMM DD, YYYY") +
        " " +
        orderTimeSelectValue
    ).format("MMMM DD, YYYY hh:mm a");
    console.log(formattedDateTime, "FORMATTED DATE TIME");
    const processType =
      orderProcessingType == 0
        ? dineInDesc
        : orderProcessingType == 1
          ? pickupDesc
          : deliveryDesc;
    axios
      .post(url, {
        cartItems: cartItems,
        totalPriceItems: totalPriceItems,
        total: total,
        subTotal: subTotal,
        tips: tips,
        tax: tax,
        discount: discount,
        serviceCharge: orderProcessingType < 2 ? 0.0 : serviceCharge,
        promoCode: promoCode,
        pid: "meal_balance",
        order_id: !sessionStorage.getItem("order_id")
          ? sessionStorage.getItem("order_id")
          : 0,
        orderProcessingType: processType,
        orderProcessingDate: {
          date: d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
          time: orderTimeSelectValue,
        },
        selectedAddress: selectedAddress,
        instructions: sessionStorage.getItem("instuctions"),
        pickupTimebyRevenueCenter: [
          { [(processType ?? "").toLowerCase()]: orderTimeSelectValue },
        ],
        serverName:
          tableNumber && tableNumber != "" && tableNumber != "none"
            ? "eServer"
            : enableKiosk === 1
              ? "eKiosk"
              : "eCommerce",
        tableNumber: tableNumber,
      })
      .then((response) => {
        console.log(response);
        sessionStorage.setItem("order_id", response.data.order_id);
        setCookieOrderId("orderId", response.data.order_id, {
          path: "/",
        });
        sessionStorage.setItem("user_id", response.data.user_id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const payments = [
    "Meal Credit",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      <Typography
        style={{
          fontWeight: 700,
          fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
        }}
        gutterBottom
      >
        Type of Payment
      </Typography>

      <div className={classes.root} style={{ "overflow-x": "visible" }}>
        <ImageList
          style={{
            width: "auto !important",
            "overflow-x": "visible",
            margin: "0",
          }}
          className={classes.gridList}
          cols={2.2}
        >
          {payments.map((payment) => {
            return (
              <ImageListItem
                className="content"
                key={payment}
                style={{
                  height: "auto",
                  margin: "0rem 6px",
                  padding: "1rem 0rem",
                  width: "auto !important",
                  paddingLeft: "0",
                }}
              >
                <Box
                  style={{
                    cursor: "pointer",
                    backgroundColor: buttonColor,
                    fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    color: buttonText,
                    borderColor: buttonColor,
                    width: enableKiosk === "0" ? "inherit" : "200px",
                    height: enableKiosk === "0" ? "inherit" : "120px",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100px"
                  padding="1rem"
                  border="1px solid #bebebe"
                  borderRadius="5px"
                  onClick={() => {
                    if (
                      cartItems &&
                      cartItems.length > 0 &&
                      orderProcessingDate
                    ) {
                      //Commented because it cause issue during checkout even if time was already selected
                      // if (!selectedTime) {
                      //   Swal.fire({
                      //     icon: "error",
                      //     title: "Oops...",
                      //     text: "Please select a time before submitting your order.",
                      //     showConfirmButton: "OK",
                      //     // timer: 4000,
                      //     // timerProgressBar: true,
                      //     // position: 'bottom-end',
                      //   });
                      //   return;
                      // }
                      setOrder();
                      setOpenUserCards(true);
                      dispatch({
                        type: "SET_SELECTED_MODE_PAYMENT",
                        payload: payment,
                      });
                    } else {
                      if (!orderProcessingDate) {
                        alert("Please provide Service Option details!");
                      } else {
                        alert("Your Check is Empty!");
                      }
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      style={{
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                    >
                      {payment}
                    </Typography>
                    {payment === "Meal Credit" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "CARDICONS" + ".svg"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                    {payment === "Gift Cards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "GIFTCARD" + ".webp"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                    {payment === "Rewards" && (
                      <img
                        height="auto"
                        width="100%"
                        alt={payment}
                        title={payment}
                        loading="lazy"
                        src={siteMedSrc + "COIN" + ".webp"}
                        style={{
                          maxWidth: "70px",
                          margin: "0px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
        <PaymentNested />
      </div>
      {enableQRFooter == 1 ? <CustomerSatisfactionQRCode /> : null}
    </>
  );
};

export default PaymentSelectorSL;
