import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { MenuNavigation } from "../components/MenuNavigation";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../config";
import AOS from "aos";
import "aos/dist/aos.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantURL = process.env.REACT_APP_SITE;
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export const EloyaltyForgotPW = () => {
  useEffect(() => {
    document.title = "Forgot Password";
  }, []);

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            title="ServingIntel"
            alt="Point of Sale: Senior Living POS System"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  useEffect(() => {
    const eloyaltyContentText = config.eloyaltycontent;

    seteloyaltyContent(eloyaltyContentText);
  }, []);

  const [eloyaltyContent, seteloyaltyContent] = useState("");

  const theme = createTheme();

  const [email, setEmail] = useState();
  const history = useHistory();

  const submit = () => {
    if (!email) {
      Swal.fire({
        title: "Invalid Input",
        text: "Fields marked with * are required",
        icon: "Info",
        confirmButtonText: "OK",
      });
      return false;
    }

    const url =
      apiBaseURL +
      "/customer/eloyalty-request-reset-password/" +
      serverId +
      "/" +
      siteId +
      "/" +
      email;
      axios
      .get(url, {})
      .then((response) => {
        console.log(response);
 
        if (response.data) {
          Swal.fire({
            title: "Password Reset Request",
            text: "Your password reset verification code has been sent to your email. Copy the code and paste it in the Verification Code field.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            history.push("/reset-password"); // Redirect after OK button is clicked
          });
        } else {
          Swal.fire({
            title: "Password Reset Request Failed!",
            text: "You are not yet registered. Please create your account.",
            icon: "warning",
            confirmButtonText: "OK",
          }).then(() => {
            history.push("/register"); // Redirect after OK button is clicked
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "An unexpected error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    if (mail) {
      Swal.fire({
        title: "Email Validation",
        text: "Invalid Email Address '" + mail + "'.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }

    return false;
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div
      className="customBg"
      data-aos-easing="ease-in-out"
      data-aos-delay="0"
      data-aos="fade-top"
    >
      <MenuNavigation />
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${restaurantName}: ${eloyaltyContent.title}`}</title>
            <link rel="canonical" href={`${restaurantURL}forgot-password`} />
            <meta
              name="description"
              content={`${eloyaltyContent.description}`}
            />
            <meta name="keywords" content={`${eloyaltyContent.keywords}`} />
            <meta
              property="og:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="og:description"
              content={`${eloyaltyContent.description}`}
            />
            <meta
              property="twitter:title"
              content={`${restaurantName}: ${eloyaltyContent.title}`}
            />
            <meta
              property="twitter:description"
              content={`${eloyaltyContent.description}`}
            />
          </Helmet>
        </HelmetProvider>
        <Container
          className="eLoyaltyBg"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos="fade-top"
          maxWidth="md"
          style={{ marginTop: "1rem" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <Typography
              component="h4"
              className="white"
              variant="h4"
              style={{ marginTop: "30px", fontWeight: "550" }}
            >
              Welcome to
            </Typography>
            <a href={`${restaurantURL}`} target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".webp"}
                width="100%"
                height="100%"
                title={`${restaurantName} | ${eloyaltyContent.title}`}
                alt={`${restaurantName} | ${eloyaltyContent.description}`}
                loading="lazy"
              />
            </a>
            <Paper
              className="formBG"
              data-aos-delay="500"
              data-aos="fade-top"
              style={{
                boxShadow: "none",
                marginTop: "0",
                marginBottom: "1rem",
                background: "beige",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                variant="outlined"
                className="formBG"
                data-aos-delay="600"
                data-aos="fade-top"
              >
                <Typography
                  variant="contained"
                  style={{ margin: "20px auto 0px auto" }}
                >
                  Please enter the email address for your account. A
                  verification code will be sent to you. Once you have received
                  the verification code, you will be able to choose a new
                  password for your account.
                </Typography>
              </Box>
              <Box
                component="form"
                noValidate
                style={{
                  background: "beige",
                  width: "100%",
                  paddingBottom: "1rem",
                }}
                className="formBG"
                data-aos-delay="700"
                data-aos="fade-top"
              >
                <TextField
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onBlur={(e) => {
                    if (!validateEmail(e.target.value)) {
                      e.target.value = "";
                    }
                  }}
                  autoFocus
                />
              </Box>
            </Paper>
            <Button
              onClick={() => submit()}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: "550" }}
              className="formBG"
              data-aos-delay="800"
              data-aos="fade-top"
            >
              Submit
            </Button>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(EloyaltyForgotPW);
